<template>
  <v-card
    class="The_Style d-flex flex-column the-sidebar sidebar-left-nav"
    height="100vh"
    :width="Width"
  >
    <v-row
      class="the-sidebar-tab flex-row"
      align="center"
      v-on:click="collapse = !collapse"
    >
      <transition name="fade" mode="out-in" tag="div">
        <v-img
          :src="$store.state.icons.icons['Blue@3x']"
          v-if="!collapse"
          height="24"
        ></v-img>

        <img
          :src="$store.state.icons.icons['Logotype@3x']"
          v-if="collapse"
          style="height: 24px"
        />
      </transition>
      <!-- <img :src="$store.state.icons.icons['favicon-32x32']" 
                    style="margin-left:-5px"
                    /> 
                        <b v-if="collapse">
                            The <br> Build
                            </b>  
			-->
    </v-row>

    <router-link
      tag="div"
      to="/home"
      active-class="active"
      :class="{ active: this.active_class === '/home' }"
    >
      <v-row
        class="the-sidebar-tab menu-tab flex-row flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['view-list']"
          class="sidebar-icons"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Dashboard</font>
        </transition>
      </v-row>
    </router-link>

    <router-link
      tag="div"
      to="/contractors"
      active-class="active"
      :class="{ active: this.active_class === '/contractors' }"
    >
      <v-row
        class="the-sidebar-tab menu-tab flex-row flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['Contractors']"
          class="sidebar-icons"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Contractor</font>
        </transition>
      </v-row>
    </router-link>

    <router-link tag="div" to="/contractor-create" active-class="active">
      <v-row
        class="the-sidebar-tab menu-tab flex-row flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['user-add']"
          class="sidebar-icons"
          style="height: 24px"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Add Contractor</font>
        </transition>
      </v-row>
    </router-link>

    <router-link
      tag="div"
      to="/distributors"
      active-class="active"
      :class="{ active: this.active_class === '/distributors' }"
    >
      <v-row
        class="the-sidebar-tab menu-tab flex-row flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['Distributors']"
          class="sidebar-icons"
          style="height: 22px"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Distributor</font>
        </transition>
      </v-row>
    </router-link>

    <router-link tag="div" to="/distributor-invite" active-class="active">
      <v-row
        class="the-sidebar-tab menu-tab flex-row flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['user-add']"
          class="sidebar-icons"
          style="height: 24px"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Add Distributer</font>
        </transition>
      </v-row>
    </router-link>

    <router-link tag="div" to="settings" active-class="active">
      <v-row
        class="the-sidebar-tab menu-tab flex-row mt-auto flex-nowrap align-center"
        align="center"
      >
        <img
          :src="$store.state.icons.icons['Users@3x']"
          class="sidebar-icons"
        />
        <transition name="fade" mode="out-in">
          <font v-if="collapse">Settings</font>
        </transition>
      </v-row>
    </router-link>

    <v-row
      class="the-sidebar-tab menu-tab flex-row mt-auto flex-nowrap align-center"
      align="center"
      @click="Logout()"
    >
      <img
        :src="$store.state.icons.icons['Logout']"
        class="sidebar-icons"
        style="height: 16px"
      />
      <transition name="fade" mode="out-in">
        <font v-if="collapse">Logout</font>
      </transition>
    </v-row>
  </v-card>
</template>

<script>
import { userlogout } from "../../services/authservices";

export default {
  data() {
    return {
      collapse: false,
      width: 80,
      active_class: "",
    };
  },

  computed: {
    Width() {
      this.collapse === true ? (this.width = 200) : (this.width = 80);
      return this.width;
    },

    isActive() {
      if (this.$router.currentRoute.matched[1].path === "/contractors") {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    $route(to, from) {
      this.classActive();
    },
  },

  methods: {
    async Logout() {
      await userlogout();
      //   this.$store.dispatch("authLogout");
    },
    classActive() {
      if (this.$router.currentRoute.matched[1].path === "/contractors") {
        this.active_class = "/contractors";
      } else if (
        this.$router.currentRoute.matched[1].path === "/distributors"
      ) {
        this.active_class = "/distributors";
      } else if (
        this.$router.currentRoute.matched[1].path === "/home" ||
        this.$router.currentRoute.fullPath === "/"
      ) {
        this.active_class = "/home";
      } else {
        this.active_class = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.the-sidebar {
  background: #162a51 !important;
  box-shadow: 0 12px 44px 0 rgba(75, 109, 130, 0.11) !important;
  border-radius: 0px !important;
  padding: 0px;
  position: relative;

  transition: width 0.3s linear, transform 0.3s ease-in-out;

  .the-sidebar-tab {
    max-height: 80px !important;
    padding: 28px;
    margin: 0px;
    cursor: pointer;
  }

  .sidebar-icons {
    filter: brightness(0) saturate(100%) invert(72%) sepia(5%) saturate(3438%)
      hue-rotate(184deg) brightness(95%) contrast(86%);
    height: 24px;
  }

  .menu-tab {
    background: #203765 !important;
    border-bottom: 1px solid #162a51;
  }

  .active {
    .menu-tab {
      background: #009ffd !important;
    }

    .sidebar-icons {
      filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(0%)
        hue-rotate(231deg) brightness(103%) contrast(102%);
      height: 24px;
    }
  }

  b {
    padding-top: 8px;
    font-family: Inter !important;
    font-size: 11px !important;
    color: #fbfbfb !important;
    line-height: 11px;
  }

  font {
    font-family: Inter-Medium;
    font-size: 15px !important;
    color: #fbfbfb !important;
    margin-left: 28px;
  }

  .sidebar-left-nav {
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-delay: 2s !important;
    transition: opacity 2s !important;
  }

  .fade-enter,
  .fade-leave-to {
    transition: opacity 0.1s !important;
  }
}
</style>
